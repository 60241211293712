footer {
    min-height: $size-footer-height;
    background-color: #cf453a;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        margin: 0 auto;
        padding: 0 7%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-between;
        @extend .text-color-grey;
        @extend .font-footer;
        .copyright {
            //font-size: 15px;
            //margin-bottom: 10px;
            color: $color-white;
        }
        .mediaweb {
            //font-size: 15px;
            //margin-bottom: 10px;
            a {
                color: $color-white;
                @extend .text-color-grey;
                @extend .flex;
                @extend .items-center;
                img {
                    margin: -7px 0 0 0;
                }
                span { padding-left: 5px;}
                &:hover {
                    color: #3f3e3c;
                }
            }
        }
        @media screen and (max-width: $size-xs-max) {
            width: $size-footer-width-mobile;
        }
    }
}
