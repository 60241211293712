// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

.ls-gui-element.ls-bar-timer {
    display: none;
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
//@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-image-text {
    .text-side {
        width: 50%;
        background: #3f3e3c;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 35px 0 7%;
        h3 {
            font-weight: normal;
            font-size: $font-size-30;
            color: $color-white;
            padding-bottom: 10px;
        }
        .border {
            width: 130px;
            height: 7px;
            background: #cf453a;
            margin-bottom: 50px;
        }
        p {
            font-weight: normal;
            font-size: $font-size-22;
            color: $color-white;
            text-align: justify;
            margin: 0;
            line-height: 1.5;
        }
        a.btn-link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 170px;
            height: 45px;
            background: #cf453a;
            transition: 0.3s;
            position: absolute;
            bottom: 15%;
            right: 35px;
            h5 {
                font-weight: normal;
                font-size: $font-size-14;
                color: $color-white;
                text-transform: uppercase;
                transition: 0.3s;
            }
            &:hover {
                background: $color-white;
                transition: 0.3s;
                h5 {
                    color: #cf453a;
                    transition: 0.3s;
                }
            }
        }
    }
    .image-side {
        width: 50%;
    }
    @media screen and (max-width: 1600px) {
        .text-side {
            a.btn-link {
                position: initial;
            }
        }
        @media screen and (max-width: 1440px) {
            .text-side {
                p {
                    font-size: 1rem;
                }
            }
            @media screen and (max-width: 1340px) {
                .text-side {
                    h3 {
                        font-weight: normal;
                        font-size: 1.35rem;
                    }
                    .border {
                        margin-bottom: 35px;
                    }
                }
                @media screen and (max-width: 1150px) {
                    .container-fluid.row {
                        flex-direction: column;
                        .text-side {
                            width: 100%;
                            padding: 65px 5%;
                        }
                        .image-side {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

#section-text {
    padding: 25px 2%;
    text-align: center;
    h4 {
        font-weight: normal;
        font-size: $font-size-36;
        color: #cf453a;
        text-shadow: 0 0 10px $color-black;
        line-height: 1.3;
    }
    @media screen and (max-width: $size-sm-max) {
        h4 br {
            display: none;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 25px 5%;
        }
    }
}

#section-parallax {
    background: url(../images/accueil_section03_bg.jpg) no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    min-height: 785px;
    margin-bottom: 55px;
    padding: 0 7%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
        font-weight: normal;
        font-size: $font-size-28;
        color: $color-white;
        padding-bottom: 10px;
    }
    .border {
        width: 130px;
        height: 7px;
        background: #cf453a;
        margin-bottom: 50px;
    }
    .section-list {
        padding-bottom: 50px;
        ul {
            list-style: none;
            position: relative;
            li {
                font-weight: normal;
                font-size: $font-size-24;
                text-transform: initial;
                color: $color-white;
                line-height: 1.5;
                padding-bottom: 5px;
                padding-left: 25px;
                &::before {
                    position: absolute;
                    content: " ";
                    background-image: url(../images/accueil_section03_crochet.png);
                    left: 0;
                    margin-top: 10px;
                    width: 15px;
                    height: 14px;
                }
            }
        }
        .left {
            width: 35%;
            padding-right: 5%;
        }
    }
    h4 {
        font-weight: normal;
        font-size: $font-size-30;
        color: $color-white;
        padding-bottom: 10px;
    }
    p {
        font-weight: normal;
        font-size: $font-size-22;
        color: $color-white;
        margin: 0;
        line-height: 1.5;
    }
    a.btn-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 175px;
        height: 45px;
        background: #cf453a;
        transition: 0.3s;
        margin-top: 35px;
        h5 {
            font-weight: normal;
            font-size: $font-size-14;
            color: $color-white;
            text-transform: uppercase;
            transition: 0.3s;
        }
        &:hover {
            background: $color-white;
            transition: 0.3s;
            h5 {
                color: #cf453a;
                transition: 0.3s;
            }
        }
    }
    @media screen and (max-width: 1700px) {
        .section-list .left {
            width: 50%;
        }
        @media screen and (max-width: 1024px) {
            .section-list .left {
                width: 50%;
            }
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/accueil_section03_bg_m.jpg) no-repeat;
                background-size: cover;
                background-repeat: no-repeat;
                background-attachment: fixed;
                background-position: center;
                min-height: 600px;
                padding: 65px 5%;
                .section-list {
                    flex-direction: column;
                    .left {
                        width: 100%;
                        margin-bottom: -10px;
                    }
                }
            }
        }
    }
}

#section-burst {
    background: #3f3e3c;
    padding: 75px 5%;
    margin-bottom: 55px;
    .burst {
        h3 {
            text-align: center;
            font-weight: normal;
            font-size: $font-size-28;
            color: $color-white;
            padding-bottom: 10px;
        }
        .border {
            width: 130px;
            height: 7px;
            background: #cf453a;
            margin: 0 auto;
        }
        a.link-burst {
            .image {
                margin: 35px 0px;
                position: relative;
                .img-responsive {
                    margin: auto;
                }
                img {
                    &:nth-child(1) {
                        opacity: 1;
                        transition: 0.3s;
                    }
                    &:nth-child(2) {
                        position: absolute;
                        opacity: 0;
                        top: 0;
                        left: 0;
                        right: 0;
                        transition: 0.3s;
                    }
                }
            }
            .btn-link {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 170px;
                height: 45px;
                margin: 0 auto;
                background: #cf453a;
                transition: 0.3s;
                h5 {
                    font-weight: normal;
                    font-size: $font-size-14;
                    color: $color-white;
                    text-transform: uppercase;
                    transition: 0.3s;
                }
            }
            &:hover {
                .btn-link {
                    background: $color-white;
                    text-decoration: none;
                    transition: 0.3s;
                    h5 {
                        color: #cf453a;
                        transition: 0.3s;
                    }
                }
                img {
                    &:nth-child(1) {
                        opacity: 0;
                        transition: 0.3s;
                    }
                    &:nth-child(2) {
                        opacity: 1;
                        transition: 0.3s;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1580px) {
        .burst {
            h3 {
                font-size: 1.15rem;
            }
        }
        @media screen and (max-width: 1405px) {
            .burst {
                h3 {
                    font-size: 0.9rem;
                }
            }
            @media screen and (max-width: 1085px) {
                flex-wrap: wrap;
                justify-content: center;
                .burst.px5 {
                    padding: 0 1.5rem 55px 1.5rem;
                }
                @media screen and (max-width: $size-sm-max) {
                    .burst.px5 {
                        padding: 55px 0;
                    }
                    .burst {
                        h3 {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
    }
}
section.section-header {
    margin-bottom: 40px;
}
/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 100px 5%;
    background: #3f3e3c;
    .container {
        width: 1300px;
        .title {
            padding-bottom: 50px;
            h3 {
                font-weight: 500;
                font-size: $font-size-36;
                text-transform: initial;
                color: $color-white;
                padding-bottom: 5px;
            }
            .border {
              width: 130px;
              height: 5px;
              background: #cf453a;
              margin-bottom: 20px;
            }
            p {
                color: $color-white;
                font-weight: 400;
            }
        }
        .section-info.flex {
            .left-side {
                width: 50%;
                margin-right: 50px;
            }
            .right-side {
                width: 50%;
            }
            h3 {
                font-weight: 500;
                font-size: $font-size-30;
                color: $color-white;
                text-transform: initial;
            }
        }
        .section-heures-map {
            padding-top: 50px;
            .left-side {
                width: 50%;
                margin-right: 50px;
                .info {
                    padding-top: 12px;
                }
            }
            .right-side {
                width: 60%;
            }
            h4 {
                font-weight: 500;
                font-size: $font-size-24;
                color: $color-white;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .container {
            width: 100%;
        }
        @media screen and (max-width: $size-sm-max) {
            .container {
                .section-heures-map {
                    flex-direction: column;
                    .left-side {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 25px;
                    }
                    .right-side {
                        width: 100%;
                    }
                }
            }
            @media screen and (max-width: $size-xs-max) {
                padding: 65px 5%;
                .container {
                    padding: 0;
                    .section-info.flex {
                        flex-direction: column;
                        .left-side {
                            width: 100%;
                            margin-bottom: 25px;
                        }
                        .right-side {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}



section.realisations {
    background-color: #3f3e3c;
    padding: 50px 5%;
    p {
        color: $color-white;
        font-weight: 400;
        font-size: 1.1rem;
        margin: 0;
        line-height: 1.5;
    }
    .masonry {
        column-gap: 16px;
        columns: 3;
        @media (min-width: 1200px) {
        }
        @media (max-width: 992px) {
          columns: 2;
        }
        @media (max-width: 768px) {columns: 1;}
        .grid {
            display: inline-block;
            margin-bottom: 16px;
            position: relative;
            &:before {
                content:'';
                position:absolute;
                top:0;
                right:0;
                bottom:0;
                left:0;
            }
            img {
                width: 100%;
            }
            &__text {
                &_title {
                    font-size: 26px;
                    font-weight:400;
                    color: $color-white;
                    transition: .3s;
                    margin: 0px 0px 10px 0px;
                }
                &_border {
                    width: 130px;
                    height: 5px;
                    background: #cf453a;
                    margin-bottom: 20px;
                }
                &__description {
                    font-size: 14px;
                    font-weight:300;
                }
            }
            &:hover {
                img {
                    opacity: .6;
                    transition: .3s;
                }
                .grid__text {
                    .grid__text_title {
                        color: #c13e32;
                        transition: .3s;
                    }
                }
            }
        }
    }
}


/*******************************************************************************
* EMPLOI
*******************************************************************************/

#section-emplois {
    padding: 75px 5%;
    background: #3f3e3c;
    .section-text {
        padding-bottom: 50px;
        padding-left: 25px;
        div.border {
            width: 130px;
            height: 5px;
            background: #cf453a;
            margin-bottom: 20px;
        }
        p {
            color: $color-white;
            font-weight: 400;
        }
        @media screen and (max-width: $size-xs-max) {
            border-left: none;
            padding-left: 0;
        }
    }
    .emplois {
        padding: 0 25px 0 25px;
        section.emplois-liste {
            .item-poste.emploi {
                width: 50%;
                padding: 0 0 65px 0;
                h4 {
                    font-weight: 500;
                    font-size: $font-size-26;
                    color: $color-white;
                    padding-bottom: 25px;
                }
                p {
                    color: $color-white;
                    line-height: 1.3;
                    font-weight: 400;
                }
                ul {
                    padding-top: 10px;
                    li {
                        font-weight: normal;
                        text-transform: initial;
                        font-size: $font-size-18;
                        color: $color-white;
                        line-height: 1.3;
                        padding-bottom: 5px;
                    }
                }
                .btn {
                    margin-top: 25px;
                    width: 155px;

                }
                &:nth-child(odd) {
                    padding-right: 100px;
                }
                @media screen and (max-width: $size-sm-max) {
                    width: 100%;
                    &:nth-child(odd) {
                        padding-right: 0;
                    }
                }
            }
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 0;
        }
    }
    h3 {
        font-weight: 500;
        font-size: $font-size-26;
        color: $color-white;
    }
}

section#section-form-emploi {
    padding: 100px 5% 25px 5%;
    background-color: $color-white;

    .label-wrapper {
        width: 150px;
    }
    @media screen and (max-width: $size-xs-max){
        .items-baseline {
            flex-direction: column;
            .label-wrapper, .flex-auto {
                width: 100%;
            }
        }
        @media screen and (max-width: $size-slider-breakpoint){
            .bouton {
                width: 200px;
                margin-top: 15px;
            }
        }
    }
}

/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {


}

@media screen and (max-width: $size-sm-max) {


}

@media screen and (max-width: $size-xs-max) {


}
