.slideshow-wrap {
    position: relative;
    margin-bottom: 55px;
    background-color: black;
    @media screen and (max-width: 1435px) {
        margin-bottom: 5px;
    }
    //#layerslider { display: block; }
    //#layerslider-mobile { display: none; }
    //@media screen and (max-width: $size-slider-breakpoint) {
    //    #layerslider { display: none; }
    //    #layerslider-mobile { display: block; }
    //}

    a.ls-gui-element.ls-nav-prev, a.ls-gui-element.ls-nav-next {
        background: #cf453a;
        border-radius: 0;
    }
    .ls-v6 .ls-nav-prev:after, .ls-v6 .ls-nav-next:after {
        margin-top: -13px;
    }
    .ls-v6 .ls-nav-prev {
        left: 47%;
        top: 100%;
        @media screen and (max-width: 1435px) {
            display: none;
        }
    }
    .ls-v6 .ls-nav-next {
        right: 47%;
        top: 100%;
        @media screen and (max-width: 1435px) {
            display: none;
        }
    }
    .ls-v6 .ls-nav-prev:hover, .ls-v6 .ls-nav-next:hover {
        transform: none;
        background: #3f3e3c;
    }

    .ls-icon-unmuted, .ls-icon-muted {
      display: none!important;
    }
    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: $font-size-24;
        text-align: center;
        padding-bottom:0.5rem;
    }
    .title {
        @extend .font-2-normal;
        text-transform: uppercase;
        color: $color-primary;
        font-size: $font-size-50;
        text-align: center;
    }
    .ls-l a {
        width: auto;
    }

    .caption {
        position: absolute;
        top: 45%;
        left: 51%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;
        h3 {
            text-align: center;
            font-weight: normal;
            font-size: $font-size-50;
            color: $color-white;
            text-shadow: 0 0 10px $color-black;
            span {
                color: #cf453a;
            }
        }
        @media screen and (max-width: 1435px) {
            h3 br {
                display: none;
            }
            @media screen and (max-width: 1024px) {
                top: 40%;
                h3 {
                    font-size: 2.2rem;
                }
                @media screen and (max-width: $size-sm-max) {
                    h3 {
                        font-size: 1.95rem;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}
        .caption {
            top: 60%;
            width: 80%;
            h3 {
                font-size: 2.2rem;
            }
        }
    }
}
